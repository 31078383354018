import * as React from 'react';

import classNames from 'classnames';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import TextField from '@components/Formik/TextField';
import { FormElementErrorMessage } from '@components/Forms/FormElement';

import validationSchema from './validationSchema';

import { useTranslation } from '@external/react-i18next';

import { Label, Placeholder, SearchFormValues, Title } from './types';

interface Props {
  initialValues: SearchFormValues;
  loading: boolean;
  submitHandler: (formValues: SearchFormValues) => void;
  isLabelsHidden?: boolean;
  title?: Title;
  label?: Label;
  placeholder?: Placeholder;
  disabled?: boolean;
  isSearchFieldClassEnabled?: boolean;
}

const SearchMembersForm: React.FC<Props> = ({
  initialValues,
  loading,
  submitHandler,
  isLabelsHidden = false,
  title = { classes: '', value: '' },
  label = { name: '', email: '' },
  placeholder = { name: '', email: '' },
  disabled = false,
  isSearchFieldClassEnabled = false,
}) => {
  const { classes: titleClasses, value: titleValue } = title;
  const { name: labelName, email: labelEmail } = label;
  const { name: placeholderName, email: placeholderEmail } = placeholder;

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={validationSchema(t)}
    >
      {formProps => (
        <Form>
          <h2 className={`text-xs font-bold ${titleClasses}`}>{titleValue}</h2>
          <div className="tablet:flex tablet:items-end mb-10 tablet:mb-5">
            <div
              className={classNames('tablet:mr-4 mb-4 tablet:mb-0', {
                'tablet:w-72 desktop:w-72': isSearchFieldClassEnabled,
              })}
            >
              <TextField
                hasMargins={false}
                name="name"
                label={labelName || t('members.search-name-label', 'Name')}
                labelHidden={isLabelsHidden}
                placeholder={
                  placeholderName ||
                  t('members.search-name-placeholder', 'Search by name')
                }
                onEnterHandler={() => submitHandler(formProps.values)}
                disabled={disabled}
              />
            </div>
            <div
              className={classNames('tablet:mr-4 mb-4 tablet:mb-0', {
                'tablet:w-72 desktop:w-72': isSearchFieldClassEnabled,
              })}
            >
              <TextField
                hasMargins={false}
                name="email"
                label={labelEmail || t('members.search-email-label', 'Email')}
                labelHidden={isLabelsHidden}
                placeholder={
                  placeholderEmail ||
                  t('members.search-email-placeholder', 'Search by email')
                }
                onEnterHandler={() => submitHandler(formProps.values)}
                disabled={disabled}
              />
            </div>
            <Button
              dataTestId="button-test-id"
              disabled={loading || disabled}
              icon="ui/search"
              small
              type="button"
              clickHandler={() => submitHandler(formProps.values)}
            >
              {t('members.search-submit', 'Search')}
            </Button>
          </div>
          {!formProps.isValid && !!formProps.submitCount && (
            <div className="mb-4">
              <FormElementErrorMessage id="global">
                {t(
                  'members.search-form-errors',
                  'The form contains errors. Please review and correct them first.'
                )}
              </FormElementErrorMessage>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SearchMembersForm;
